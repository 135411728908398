<template>
    <div class="index">
        <div class="header">
            <div class="header-l">
                <img src="../../assets/image/logo.png" alt="">
            </div>
            <!-- <div class="header-c">
                <p class="p1">
                    中国广告协会战略支持平台
                </p>
                <p>
                    中国广告协会战略制作平台
                </p>
            </div> -->
            <div class="header-r">
                <span>没有账号?</span>
                
                <a @click="login">立即注册</a>
            </div>
        </div>

 <!--  以上是头部     -->

        <div class="line"></div>

        <div class="main">
            <div class="div1">绑定手机号</div>
            <div class="div2">
                <input type="text" placeholder="手机号"/>
                <div class="tishi">
                    <div class="tishi-l">*</div>
                    <div class="tishi-r">11位手机号码</div>
                </div>
            </div>
            
            <div class="div3" v-if="flag">
                <div class="div3-1" @click="yanzheng">点击此处进行验证</div>
                <div class="div3-2">
                    <div class="div3-2-l">*</div>
                    <div class="div3-2-r">点击进行验证</div>
                </div>
            </div>
            <div class="div3-y" v-if="flag1">
                <input type="text" placeholder="验证码" class="teshu"/>
                <div class="time"></div>
            </div>

            <button class="regist">立即绑定</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        components: { },
        data(){
            return{
                flag:true,
                flag1:false
            }
        },
        mounted(){//挂载钩子函数

        },
        methods:{//事件处理对象
            yanzheng(){
                this.flag=false;
                this.flag1=true
            },
            login(){
                this.$router.push("/login");
            },
            // phone() {//注册手机号正则验证
            //     var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
            //     if (!myreg.test(this.pone)) {
            //         this.flagPhone=true;
            //     } else {
            //         this.flagPhone=false;
            //     }
            // },
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/common.scss";
    @mixin icon($m) {
        $url: '../../assets/image/' + $m;
    @include bgImage($url);
        background-size: contain;
    }
    .index{
        width: 100%;
        height: 100%;
        /*---以下是头部----*/
        .header{
            width: 1200px;
            height: 149px;
            /*background: red;*/
            margin: 0 auto;
            >div{
                float: left;
            }
           .header-l {
                width: 404px;
                height: 149px;
                img {
                    display: block;
                    width: 100%;
                    height: 58px;
                    margin-top: 50px;
                }
            }
            .header-c{
                margin-left: 23px;
                p{
                    height: 27px;
                    font-size: 16px;
                    color: #333333;
                }
                .p1{
                    margin-top: 46px;
                }
            }
            .header-r{
                width: 175px;
                font-size: 17px;
                line-height: 149px;
                float: right;
                text-align: right;
                span{
                    color: #666666;
                }
                a{
                    color: #0db168;
                }
            }
        }
        .main{
            width: 764px;
            height: 600px;
            margin: 0 auto;
            padding-left: 436px;
            .div1{
                height: 77px;
                font-size: 18px;
                color: #0db168;
                line-height: 77px;
            }
            .div2{
                width: 762px;
                height: 45px;
                font-size: 18px;
                background: url(../../assets/icon/shoujihao1.png) no-repeat 13px 13px;
                color: #999999;
                margin-bottom: 30px;
                input{
                    padding-left: 50px;
                    width:284px;
                    height: 43px;
                    border: 0;
                    border: 1px solid #eaeaea;
                    background: none;
                    border-radius: 5px;
                    float: left;
                }
                .tishi{
                    float: left;
                    width: 350px;
                    height: 43px;
                    align-items: center;
                    font-size: 12px;
                    .tishi-l{
                        margin-left: 13px;
                        color: #0db168;
                        float: left;
                        line-height: 46px;
                    }
                    .tishi-r{
                        line-height: 46px;
                        float: left;
                    }
                }
            }
            
            .mima{
                position: relative;
                background: url(../../assets/icon/mima1.png) no-repeat 13px 13px;
                margin-top: 10px;
                .p1{
                    font-size: 12px;
                    position:absolute;
                    bottom: -25px;
                    color: #0db168;
                    width:300px;
                     background: url(../../assets/icon/tishi.png) no-repeat 10px 0px;
                    padding-left: 35px;
                }
            }
            .mima2{
                top:10px;
            }
           .div3{
               width: 762px;
               height: 45px;
                
               margin-bottom: 30px;

               .div3-1{
                   width:335px;
                   height: 45px;
                    line-height: 45px;
                    text-align: center;
                    float: left;
                    border: 1px solid #eaeaea;
                    font-size: 14px;
                    color: #333333;
                    border-radius: 5px;
               }
               .div3-2{
                   width:424px;
                   height: 45px;
                   float: left;
                    line-height: 45px;
                    text-align: center;
                    font-size: 12px;
                   .div3-2-l{
                        margin-left: 13px;
                        color: #0db168;
                        float: left;
                        line-height: 46px;
                    }
                    .div3-2-r{
                        line-height: 46px;
                        float: left;
                        color: #999999;
                    }  
               }
           }
           .div3-y{
               width: 337px;
               height: 45px;
               margin-bottom: 30px;
               .teshu{
                   width: 150px;
                   height: 40px;
                   border-radius: 5px;
                   color: #999999;
                      border: 1px solid #ccc;
                    background: url(../../assets/icon/yanzhengma.png) no-repeat 13px 13px;
                   float: left;
                   padding-left: 54px;
               }
               .time{
                   width: 117px;
                   height:45px;
                   background: #0db168;
                   float: left;
                   margin-left: 12px;
                   border-radius: 5px;
               }
           }
        }
        .div4{
            height: 18px;
            position: relative;
            
            input:checked+label{ // 这段代码是当input选中时,给input的相邻标签label加一个背景图片 
                 
                    background-image:url("../../assets/icon/gouxuan.png");
                   background-repeat:no-repeat;
                    background-position: 2px 2px;
            }
            .ipt1{
                display: none;
                float:left;
                background: url(../../assets/icon/shoujihao1.png) no-repeat 13px 13px;
            }
            .lab1{
                float: left;
                position:absolute;
                width: 18px;
                height:18px;
                border: 1px solid #ccc;
                top: -2px;
            }
            
            .div4-2{
                float: left;
                margin-left: 16px;
                line-height: 18px;
                .span1{
                    width: #666666;
                    margin-left: 16px;
                }
                .span2{
                    margin-left: 7px;
                    color: #0db168;
                }
            }
        }
        .regist{
            width:337px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            background: #0db168;
            border: none;
            margin-top: 30px;
            font-size: 16px;
            color: #fff;                                                               
            border: 0;
            border-radius: 5px;
        }
        .line{
            width: 100%;
            height: 0.1px;
            background: #0db168;
        }
        .log{
            padding: 52px 0;
            padding-left: 20%;
        }
        .border{
            border: 1px solid $color168;
        }

    }
</style>

}
